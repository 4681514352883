import React from "react";
import "./Landing.css";
import Logo from "../../images/S&C_Logo.png";
import Salon from "../../images/salon2.jpeg";
import { BiLogoInstagramAlt } from "react-icons/bi";
import { IoIosMail } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";

function Landing() {
  return (
    <div className="container">
      <img src={Logo} alt="Soft and Cut Logo" className="logo" />
      <div className="main">
        <img src={Salon} alt="Salon" className="image" />
        <div className="contact_wrapper">
          <div className="contact">
            <h1>
              Kontakt <br />
              <i>Contact</i>
            </h1>
            <p>
              <a
                href="https://www.instagram.com/inaholub"
                target="_blank"
                rel="noopener noreferrer"
              >
                <BiLogoInstagramAlt className="icon" alt="Instagram Icon" />
              </a>
              <a
                href="https://www.instagram.com/inaholub"
                target="_blank"
                rel="noopener noreferrer"
              >
                @inaholub
              </a>
            </p>

            <p>
              <a href="mailto:hello@softandcut.com">
                <IoIosMail className="icon" alt="Mail Icon" />
              </a>
              <a href="mailto:hello@softandcut.com">hello@softandcut.com</a>
            </p>

            <p>
              <a href="https://maps.app.goo.gl/Gz9C7Qjt5BEfDCct7">
                <FaLocationDot className="locicon" alt="Maps Icon" />
              </a>
              <a
                href="https://maps.app.goo.gl/Gz9C7Qjt5BEfDCct7"
                target="blank"
              >
                Soft & Cut <br /> Kaiserstraße 67 <br /> 1070 Wien, Österreich
              </a>
            </p>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5318.805872338206!2d16.339834848391874!3d48.19885428629287!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476d07e6115b9957%3A0xa992cb3df4cd0e88!2sSoft%20%26%20Cut!5e0!3m2!1sde!2sat!4v1711299995543!5m2!1sde!2sat"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
      <h2 className="comingSoon">more coming soon</h2>
    </div>
  );
}

export default Landing;
