import React from "react";
import "./App.css";
import Terminbuchung from "./components/Terminbuchung";
import Bestätigungsseite from "./components/Bestätigungsseite";
import LandingPage from "./components/under_construction/Landing";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <LandingPage />
      {/*      <Router>
        <Routes>
          <Route path="/" element={<Terminbuchung />} />
          <Route path="/confirmation" element={<Bestätigungsseite />} />
        </Routes>
  </Router> */}
    </div>
  );
}

export default App;
